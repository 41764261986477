import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '../../components/Box';
import Text from '../../components/Text';
import Image from '../../components/Image';
import Container from '../../components/UI/Container';
import InputGroup from '../../components/InputGroup';
import RadioGroup from '../../components/RadioGroup';
import SectionWrapper, {
  ContentArea,
  Heading,
  ButtonGroup,
  DonationForm,
  DonateButton,
} from './donateSection.style';

import heartImage from '../../assets/image/default/heart-alt.svg';

const DonateSection = ({ row, col }) => {
  const data = useStaticQuery(graphql`
    query {
      contentJson {
        paymentPolicy {
          id
          title
          text
          value
        }
        currencyOptions {
          id
          title
          value
        }
      }
    }
  `);

  const [state, setState] = useState({
    price: '',
    currency: 'usd',
    policy: 'oneTime',
  });

  const handleFormData = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleDonation = (e) => {
    e.preventDefault();
    console.log('Donation form data: ', state);

    setState({
      ...state,
      price: '',
    });
  };

  return (
    <SectionWrapper id="donate">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentArea>
              <Heading>
                Would You like to drop us an email <span>telling you story</span>?
              </Heading>
              <Text content="Let us hear and see how can we proceed on it." />
              <ButtonGroup>
                <a href="#1" className="learn__more-btn alt">
                  <span className="hyphen" />
                  <span className="btn_text">Send Us an Email </span>
                </a>
              </ButtonGroup>
            </ContentArea>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// DonateSection style props
DonateSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object,
};

// DonateSection default style
DonateSection.defaultProps = {
  // DonateSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // DonateSection col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default DonateSection;
