import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Image from '../../components/Image';
import Container from '../../components/UI/Container';
import NavbarWrapper, { MenuWrapper, Button } from './navbar.style';

import logoImage from '../../assets/image/default/logo.svg';
import heartImage from '../../assets/image/default/heart-red.png';

const Navbar = () => {
  return (
    <NavbarWrapper className="navbar">
      <Container fullWidth={true}>
        <Link className="logo" to="/charity">
          <Image src={logoImage} alt="Charity React Next Landing" />
        </Link>
        <MenuWrapper>
          <Button>
            <span className="text">CONTACT US</span>
          </Button>
        </MenuWrapper>
      </Container>
    </NavbarWrapper>
  );
};

export default Navbar;
