import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../theme/default';
import { ResetCSS } from '../assets/css/style';
import { DrawerProvider } from '../contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import DrawerSection from '../containers/DrawerSection';
import BannerSection from '../containers/BannerSection';
import FeatureSection from '../containers/FeatureSection';
import BranchSection from '../containers/BranchSection';
import WorkSection from '../containers/WorkSection';
import MilestoneBlock from '../containers/MilestoneBlock';
import HumanityBlock from '../containers/HumanityBlock';
import PromotionBlock from '../containers/PromotionBlock';
import DonateSection from '../containers/DonateSection';
import MapSection from '../containers/MapSection';
import FundraiserSection from '../containers/FundraiserSection';
import BlogSection from '../containers/BlogSection';
import ClientBlock from '../containers/ClientBlock';
import Footer from '../containers/Footer';
import {
  GlobalStyle,
  MainWrapper,
  ContentWrapper,
} from '../containers/containers.style';
import SEO from '../custom/seo';

export default () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Fragment>
        <SEO title="VeriKod" />
        <ResetCSS />
        <GlobalStyle />
        {/* Start main wrapper section */}
        <MainWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper>
            <BannerSection />
            <WorkSection />
            <MilestoneBlock />
            <HumanityBlock />
            <BranchSection />
            <PromotionBlock />
            <DonateSection />
          </ContentWrapper>
          <Footer />
        </MainWrapper>
        {/* End of main wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
