import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Image from 'gatsby-image';
import Text from '../../components/Text';
import Heading from '../../components/Heading';
import GlideCarousel from '../../components/GlideCarousel';
import GlideSlide from '../../components/GlideCarousel/glideSlide';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
  HighlightedText,
} from './bannerSection.style';

const BannerSection = () => {
  const glideOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
  };

  const data = useStaticQuery(graphql`
    query {
      contentJson {
        bannerSlides {
          id
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#work" />
      <ContentWrapper>
        <TextArea>
          {/* <HighlightedText className="highlighted_text"> */}
          {/*   <strong>NEWS</strong> 1 year. 100 Forever Families. */}
          {/*   <Icon icon={chevronRight} /> */}
          {/* </HighlightedText> */}
          <Heading content="Let's build your next idea together" />
          <Heading
            as="h4"
            content="verikod is a next generation software powerhouse looking to leverage your future plans."
          />
          <Text
            content="We are here to listen your next dream software solution and build it for you by putting all our craftmanship on delivering the best available value on time."
          />
          <Link className="learn__more-btn" to="#socialFundraising">
            <span className="hyphen" />
            <span className="btn_text">Learn More</span>
          </Link>
        </TextArea>
        <ImageArea>
          <GlideCarousel
            carouselSelector="charitySlide"
            options={glideOptions}
            nextButton={<span className="next_arrow" />}
            prevButton={<span className="prev_arrow" />}
          >
            <Fragment>
              {data.contentJson.bannerSlides.map((slide) => (
                <GlideSlide key={slide.id}>
                  <Image
                    fluid={
                      (slide.thumb_url !== null) | undefined
                        ? slide.thumb_url.childImageSharp.fluid
                        : {}
                    }
                    alt={`Charity slide image ${slide.id}`}
                    className="slide_image"
                  />
                </GlideSlide>
              ))}
            </Fragment>
          </GlideCarousel>
        </ImageArea>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
